import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { RichText } from 'prismic'

import { Container } from 'components/ui/layout'
import * as s from './Hero.module.css'

const cn = classNames.bind(s)

const Hero = ({ data, theme }) => {
  return (
    <div className={cn('wrapper')} data-theme={theme}>
      <Container className={cn('text')}>
        <div className={cn('fakeGrid')}>
          <div className={cn('spacer')} />
          <div className={cn('main')}>
            {data?.hero_heading && <div className={cn('heading')}>{data?.hero_heading}</div>}
            {data?.hero_content && <div className={cn('content')}>{RichText.render(data.hero_content.richText)}</div>}
          </div>
          <div className={cn('spacer')} />
        </div>
      </Container>
    </div>
  )
}

Hero.propTypes = {
  data: PropTypes.shape({
    hero_heading: PropTypes.string,
    hero_content: PropTypes.object,
  }),
  theme: PropTypes.string,
}

export default memo(Hero)
