import React, { useLayoutEffect, useEffect, useRef } from 'react'
import { useViewportScroll } from 'framer-motion'
import { useWindowHeight } from '@react-hook/window-size'
import classNames from 'classnames/bind'

import useUIContext from 'context/ui'

import Layout from 'layouts'
import SEO from 'components/seo'
import { Container } from 'components/ui/layout'
import Slices from 'components/ui/slices'
import { Each } from 'components/ui/slices/ImagesSlice'

import Hero from './components/hero'
import RelatedProjects from './components/related-projects'

import * as s from './ProjectPage.module.css'
const cn = classNames.bind(s)

const ProjectPage = ({ data, location }) => {
  const { scrollY } = useViewportScroll()
  const windowHeight = useWindowHeight(1000)
  const setHeaderTheme = useUIContext(s => s.setHeaderTheme)
  const isPageTransitionActive = useUIContext(state => state.isPageTransitionActive)

  const local = useRef({ scrolled: false, opacity: 1, overscroll: true }).current

  const page = data?.prismicWorkProject?.data
  const background = location?.state?.color || page?.blob_color
  const metaTitle = page?.page_meta_title
  const metaDescription = page?.page_meta_description
  const metaThumbnail = page?.page_meta_thumbnail
  const relatedProjects = page?.related_projects?.map(({ related_project }) => related_project) || []
  const heroDarkMode = page?.hero_dark_mode
  const theme = heroDarkMode ? 'dark' : 'light'
  const heroImage = page?.hero_imageSharp || page?.hero_image

  const threshold = windowHeight * 0.8

  useLayoutEffect(() => {
    setHeaderTheme(isPageTransitionActive ? '' : theme)
    return () => {
      setHeaderTheme()
    }
  }, [isPageTransitionActive])

  const handleScroll = (val, direction) => {
    const isScrolled = val > threshold
    if (local.scrolled !== isScrolled) {
      local.scrolled = isScrolled
      setTimeout(() => {
        const headerTheme = !local.scrolled ? theme : null
        setHeaderTheme(headerTheme)
      }, 0)
    }

    // Add transition class depending on scroll direction
    let newOpacity = local.opacity
    if (direction > 0 && isScrolled) {
      newOpacity = 0
    }
    if (direction < 0 && !isScrolled) {
      newOpacity = 1
    }

    if (val > 0 && local.opacity !== newOpacity) {
      local.opacity = newOpacity
      setTimeout(() => {
        // This creates lag - can we skip and change when text scrolled out of view? yes probably
        const theme = heroDarkMode && !isScrolled ? 'dark' : 'light'
        document.documentElement.setAttribute('data-theme', theme)
        document.documentElement.style.background = newOpacity ? background : 'var(--color-background)'
      }, 0)
    }
  }

  useEffect(
    () =>
      scrollY.onChange(val => {
        handleScroll(val, scrollY.getVelocity())
      }),
    [],
  )

  return (
    <Layout fadeIn={true} background={background} labCTA={false} theme={theme} className={cn('projectPage')}>
      <SEO title={metaTitle} description={metaDescription} thumbnail={metaThumbnail} />
      <Hero data={{ hero_heading: page.hero_heading, hero_content: page.hero_content }} theme={theme} />
      {!!heroImage && (
        <Container>
          <Each
            image={page?.hero_image}
            alt={page?.hero_image?.alt}
            video_mp4={page?.hero_video_mp4}
            video_webm={page?.hero_video_webm}
            video_mobile_mp4={page?.hero_video_mobile_mp4}
            video_mobile_webm={page?.hero_video_mobile_webm}
          />
        </Container>
      )}
      {page?.body?.map(Slices)}
      <RelatedProjects projects={relatedProjects} />
    </Layout>
  )
}

export default ProjectPage
