import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import { linkResolver } from 'prismic'
import { prismicRepo } from '../../prismic-configuration'

import ProjectPage from 'views/work/ProjectPage'

export const query = graphql`
  query ProjectPageQuery($uid: String) {
    prismicWorkProject(uid: { eq: $uid }, lang: { eq: "en-us" }) {
      _previewable
      ...ProjectFrag
    }
  }
`

export default withPrismicPreview(ProjectPage, [
  {
    repositoryName: prismicRepo,
    linkResolver,
  },
])
