import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import { Container } from 'components/ui/layout'
import ProjectCard, { ProjectsContainer } from 'components/ui/project-card'

import * as s from './RelatedProjects.module.css'

const cn = classNames.bind(s)

const RelatedProjects = ({ projects }) => {
  const list = projects.filter(p => !!p?.document?.uid)
  if (!list?.length) return null
  return (
    <Container className={cn('wrapper')}>
      <div className={cn('caption')}>More work</div>
      <ProjectsContainer>
        {list.map((node, i) => (
          <ProjectCard key={node.document.uid} to={`/${node.document.uid}`} node={node} offset={i * 0.01} />
        ))}
      </ProjectsContainer>
    </Container>
  )
}

RelatedProjects.propTypes = {
  projects: PropTypes.array,
}

export default RelatedProjects
